import { formatMinutesToString } from "@/utils/date";

export function buildEventName(s_program) {
  let str = `${s_program.name} `;
  let start_bound = buildEventNameBound(
    s_program.start_state,
    s_program.start_time,
    s_program.start_offset
  );
  let end_bound = buildEventNameBound(
    s_program.end_state,
    s_program.end_time,
    s_program.end_offset
  );
  return `${str} (${start_bound} - ${end_bound})`;
}

/**
 *
 * @param {Object} bound : corresponding to selelectedProgram.start_time or ..end_time
 */
export function buildEventNameBound(state, time, offset) {
  let str = "";
  if (state == 1) {
    str += "Sunrise";
  } else if (state == 2) {
    str += "Sunset";
  } else str += formatMinutesToString(time);
  if (state && offset && parseInt(offset) != 0) {
    str += `${parseInt(offset) > 0 ? "+" : ""}${formatMinutesToString(offset)}`;
  }
  return str;
}

/**
 * Extract the name of the event by removing the time range suffix 'program1  (10:00 - 12:30) --> program1'
 * @param {Object} program_name
 */
export function extractProgramName(program_name) {
  if (!program_name) return "";
  let match = program_name.match(/\(([^()]+)\)(?=[^()]*$)/gm);
  return program_name.replace(match[0], "").trim();
}
