<template>
  <div class="container">
    <div class="deck">
      <div class="card clickcard" :class="{flipped: isFlipped}">
        <div v-show="frontIsVisible" class="front face">
          <slot name="front"/>
        </div>
        <div class="back face">
          <slot name="back" v-if="backIsLoaded" @click="flipCard()"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TwoSidedCard",
  props: ['backFlipTimeout'],
  data() {
    return {
      backIsLoaded: false,
      frontIsVisible: true,
      isFlipped: false,
    }
  },
  watch: {
    isFlipped(newValue) { // Goal is to keep loaded the back component until the card is fully flipped
      if(!newValue) {
        if(this.backFlipTimeout) {
          setTimeout(() => {
            this.backIsLoaded = !this.backIsLoaded
          }, this.backFlipTimeout);
        }
      }
      else this.backIsLoaded = !this.backIsLoaded

      setTimeout(() => {
        this.frontIsVisible = !this.frontIsVisible
      }, 150)
    }
  },
  methods: {
    flipCard() {
      this.isFlipped = !this.isFlipped
    }
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.container {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.deck {
  width: 100%;
  position: relative;
  perspective: 3000px;
  border-radius: 10px;
}

.card {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: all .3s linear;
  border-radius: 10px;
}

.face {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  backface-visibility: hidden;
}

.back {
  overflow: auto;
  z-index: -1;
  display: block;
  transform: rotateY(180deg);
  box-sizing: border-box;
}

.front,
.back {
  transition: all .3s linear;
}

.front {
  z-index: 1;
  box-shadow: 5px 5px 5px #aaa;
}

.back {
  box-shadow: 0 0 0 #aaa;
}

.flipped,
.hovercard:hover {
  transform: rotateY(180deg);

  .front {
    box-shadow: 0 0 0 #aaa;
  }

  .back {
    box-shadow: 5px 5px 5px #aaa;
  }
}

.front {

  h2,
  h3 {
    text-align: center;
    color: whitesmoke;
  }
}

.back {
  h2 {
    margin: 0;
    border-radius: 10px 10px 0 0;
    padding: 10px;
    text-align: center;
    color: whitesmoke;
  }
}
</style>